.filter-item-label {
    margin: 2px;
    padding: 3px;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}

.filter-item-label input[type=checkbox] {
    display: none
}
