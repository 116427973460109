.horizontal-scroll {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

.horizontal-scroll-item {
    display: inline-block;
    float: none;
}

.logo-outline-foreground {
    stroke: white;
    stroke-width: 8px;
    fill: transparent;
}

.logo-foreground {
    fill: white;
}

.name-header {
    display: inline-block;
}

.section .btn {
    margin-top: 1em;
    margin-bottom: 1em;
}

.section {
    padding-top: 3em;
    padding-bottom: 3em;
}

.hover-background {
    opacity: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    background-color: #2cbdec;
}

.hover-background:hover {
    opacity: 0.4;
}

.section-background {
    position: relative;
    background-color: transparent;
    color: white;
}

.subsection {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
}

.section-background:before {
    background-size: cover;
    color: white;
    content: '';
    position: -webkit-sticky; /* Safari */
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}

.section-background:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: all .5s;
}

.section-background:hover:after {
    opacity: 0.4;
}

.btn.experience:active,
.btn.education:active,
.btn.projects:active {
    border-color: white;
}

/*Overview*/

.overview.section-background:after {
    background-color: #003E63;
}

.overview.section-background:before {
    background-image: url('/OverviewBackground.jpg');
}

.overview .logo-background {
    fill: #003E63;
}

/*Experience*/

.experience.section-background:after {
    background-color: #003F00;
}

.experience.section-background:before {
    background-image: url('/ExperienceBackground.jpg');
}

.experience .logo-background {
    fill: #003F00;
}

.btn.experience {
    color: #fff;
    background-color: #36590C;
    border-color: #2F4C0A;
}

/*Education*/

.education.section-background:after {
    background-color: #46004C;
}

.education.section-background:before {
    background-image: url('/OverviewBackground.jpg');
}

.education .logo-background {
    fill: #46004C;
}

.btn.education {
    color: #fff;
    background-color: #5E0E66;
    border-color: #4B0B51;
}

/*Projects*/

.projects.section-background:after {
    background-color: #4C0000;
}

.projects.section-background:before {
    background-image: url('/OverviewBackground.jpg');
}

.projects .logo-background {
    fill: #4C0000;
}

.btn.projects {
    color: #fff;
    background-color: #660E0E;
    border-color: #4F0B0B;
}